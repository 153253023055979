import { Box, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'store';
import { getFaviconOfAccountSelector, getMetaOfAccountSelector } from 'store/state/dsaSelector';
import { Helmet } from 'react-helmet-async';

const AppMeta = () => {
  const meta = useSelector(getMetaOfAccountSelector);
  const favicon = useSelector(getFaviconOfAccountSelector);
  const [metaData, setMetaData] = useState<any>(null);
  
  useEffect(() => {
    if (meta) {
      setMetaData(meta);
    } else {
      fetchMetaDataFromAPI().then((data: any) => {
        setMetaData(data); // Use the fetched data instead of `meta`
      });
    }
  }, [meta]);
  
  // Update favicon whenever metaData is updated
  useEffect(() => {
    updateFavicon(favicon);
  }, [favicon]);

  // Placeholder function simulating API call
  const fetchMetaDataFromAPI = async () => {
    // Here is an example of data fetched from API
    return {
      "title": "OBLIGR",
      "description": "OBLIGR is an AI, Blockchain, Metaverse, NFT, Cloud Company serving a unified platform for multiple services.",
      "canonical": "https://obligr.com/",
      "ogLocale": "en_US",
      "ogType": "website",
      "ogTitle": "OBLIGR",
      "ogDescription": "Communications APIs Unified Communications Contact Centers LeadFunnel Services...",
      "ogUrl": "https://obligr.com/",
      "ogSiteName": "Obligr™ AI/ML, Blockchain, Metaverse, NFT, Cloud Company",
      "themeColor": "#2296f3",
      "favicon": "/winpro.webp"
    };
  };

  const updateFavicon = (faviconUrl: string) => {
    const existingFavicon: any = document.querySelector("link[rel*='icon']");

    if (faviconUrl) {
      // Update favicon if URL is provided
      if (existingFavicon) {
        existingFavicon.href = faviconUrl;
      } else {
        const link = document.createElement('link');
        link.rel = 'icon';
        link.href = faviconUrl;
        document.getElementsByTagName('head')[0].appendChild(link);
      }
    } else {
      // Remove the favicon if no URL is provided
      if (existingFavicon) {
        existingFavicon.remove();
      }
    }
  }

  if (!metaData) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content={metaData?.themeColor} />
      <title>{metaData?.title}</title>
      <meta name="description" content={metaData?.description} />
      <link rel="canonical" href={metaData?.canonical} />
      <meta property="og:locale" content={metaData?.ogLocale} />
      <meta property="og:type" content={metaData?.ogType} />
      <meta property="og:title" content={metaData?.ogTitle} />
      <meta property="og:description" content={metaData?.ogDescription} />
      <meta property="og:url" content={metaData?.ogUrl} />
      <meta property="og:site_name" content={metaData?.ogSiteName} />
    </Helmet>
  );
};

export default AppMeta;
